var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.blastPacketProgress != null)?_c('task-rounded-dialog',{attrs:{"screen-position":_vm.screenPosition,"top-offset-pixels":_vm.topOffsetPixels,"task-name":_vm.blastPacketProgress.taskTypeName,"task-foreground-colour":_vm.blastPacketProgress.foregroundColour,"task-background-colour":_vm.blastPacketProgress.backgroundColour}},[_c('div',{staticClass:"d-flex"},[_c('v-card-text',{staticClass:"py-0"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('strong',[_vm._v(" Quantity: "+_vm._s(_vm._f("formatDecimal")(_vm.blastPacketProgress.quantity,1))+" ")])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('strong',[_vm._v(" Rate Per Hour: "+_vm._s(_vm._f("formatDecimal")(_vm.ratePerHour,1))+" ")])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('strong',[_vm._v(" Duration: "+_vm._s(_vm._f("formatDecimal")(_vm.blastPacketProgress.duration))+" ")])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('strong',[_vm._v(" Ring: "+_vm._s(_vm.first.name)+"/"+_vm._s(_vm.first.locationRingName)+" ")])])],1),_c('v-row',{staticClass:"pa-5 pb-8"},[_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.orderedTargets},scopedSlots:_vm._u([{key:"item.taskTypeName",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{staticClass:"mb-1",attrs:{"rounded":"","color":item.backgroundColour,"height":22,"value":_vm.getActualPercentage(item)},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{style:(("color: " + (item.foregroundColour)))},[_vm._v(_vm._s(item.taskTypeName))])]},proxy:true}],null,true)}),_c('v-progress-linear',{attrs:{"value":_vm.getPlannedPercentage(item)}})]}},{key:"item.selected",fn:function(ref){
var item = ref.item;
return [(item.taskTypeId === _vm.blastPacketProgress.taskTypeId)?_c('v-icon',[_vm._v("mdi-star-outline")]):_vm._e()]}},{key:"item.actual",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDecimal")(item.actual,1)))])]}},{key:"item.planned",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDecimal")(item.planned,1)))])]}},{key:"item.designed",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDecimal")(item.designed,1)))])]}}],null,false,3584709181)})],1)],1)],1),_c('v-card-actions',{staticClass:"mb-2 mx-2"},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"gray","text":""},on:{"click":_vm.onClose}},[_vm._v("Close")])],1)])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }