import _ from 'lodash';
import { defineStore } from 'pinia';
import BlastPackets from '@/lib/data/BlastPackets';
import LocationRings from '@/lib/data/LocationRings';
import Locations from '@/lib/data/Locations';
import Departments from '../data/Departments';
import {
    LocationRingsStopeModel,
    CreateLocationRingCommand,
    UpdateLocationRingCommand,
    DeleteLocationRingCommand,
    RingConfiguration,
    RingConfigurationDesign,
    LocationRingModel,
    TriggerActionResponsePlanAspectViewModel,
    UpdateLocationTriggerActionResponsePlanStatusCommand,
    PropertyDefinitionModel,
    CommonLocationTrackingViewModel,
    UpdateLocationStatusCommand,
    StopeLocationTrackerLocationViewModel,
    UpdateLocationDetailsCommand,
    SetLocationPlanningPriorityCommand,
    UpsertLocationExtendedPropertyCommand, UpdateLocationCommentaryCommand, CommentaryViewModel
} from '@/models/api';
import SelectedMineAreaStore from '@/lib/stores/SelectedMineAreaStore';
import TriggerActionResponsePlans from '@/lib/data/TriggerActionResponsePlans';
import { useMineAreaStore } from '@/lib/stores/MineAreaStore';
import LocationDetails from '@/lib/data/LocationDetails';
import { useBlastPacketsStore } from '@/lib/stores/Production/BlastPacketsStore';
import { ClientCommonLocationTracking } from '@/models/client/location-tracker';
import { ExtendedProperty } from '@/models/client/extended-property';
import ModelBuilder from '@/lib/ModelBuilder';
import RingConfigurations from '@/lib/data/RingConfigurations';

export const useLocationTrackerStore = defineStore('locationTracker', {
    state: () => {
        return {
            _departmentId: '',
            _mineAreaId: '',
            _mine: {},
            isInitialized: false,
            ringConfigurations: [] as RingConfiguration[],
            rings: [] as LocationRingsStopeModel[],
            extendedProperties: [] as PropertyDefinitionModel[],
            tarpAspects: [] as TriggerActionResponsePlanAspectViewModel[],
            patchLocationCommonInformationCallback: null as ((locationId: string, patcher: (commonInfo: ClientCommonLocationTracking) => ClientCommonLocationTracking) => void) | null
        };
    },
    getters: {
        getLocationRings(): (locationId: string) => LocationRingModel[] {
            return (locationId: string) => {
                const model = this.rings.find((x) => x.id === locationId);

                return model?.rings ?? ([] as LocationRingModel[]);
            };
        },
        getLocationRingsForLocations(): (locationIds: string[]) => LocationRingModel[] {
            return (locationIds: string[]) => {
                const models = this.rings.filter(x => locationIds.includes(x.id));

                return _.flatMap(models, m => m.rings);
            };
        },
        getOrderedTriggerActionResponsePlans(): () => TriggerActionResponsePlanAspectViewModel[] | null {
            return () => {
                return [...this.tarpAspects].sort((t1, t2) => t1.displayOrder - t2.displayOrder);
            };
        }
    },
    actions: {
        async populateStore(mineId: string, departmentId: string, patchLocationCommonInformationCallback: (locationId: string, patcher: (commonInfo: ClientCommonLocationTracking) => ClientCommonLocationTracking) => void) {
            this.isInitialized = false;
            this._departmentId = departmentId;
            this._mineAreaId = mineId;

            await useMineAreaStore().initializeMineAreaStore(mineId);
            this._mine = SelectedMineAreaStore.getSelectedMineArea();
            //await this.populateLocationRings(departmentId);
            await this.populateTriggerActionResponsePlans(departmentId);
            await this.populateRingConfigurations(mineId);
            this.extendedProperties = await Departments.GetPropertyDefinitions(departmentId, true);

            this.patchLocationCommonInformationCallback = patchLocationCommonInformationCallback;

            this.isInitialized = true;
        },
        async populateLocationRings(departmentId: string) {
            const lr = await LocationRings.getByDepartment(departmentId);
            if (lr) {
                this.rings = lr.locations;
            }
        },
        async populateRingConfigurations(mineAreaId: string) {
            const ringConfigurations = await RingConfigurations.get(mineAreaId);
            this.ringConfigurations = ringConfigurations.map(x=>({
                ...x,
                holes: [...x.holes].sort((a, b) => a.displayOrder - b.displayOrder)
            })).sort((a, b) => a.name!.localeCompare(b.name!));
        },
        async populateLocationRingsForStope(departmentId: string, stopeId: string) {
            const lr = await LocationRings.getByStope(departmentId, stopeId);

            if (lr) {
                for (const stope of lr.locations) {
                    this.rings = [...this.rings.filter(x => x.stopeId !== stopeId), ...lr.locations];
                }
            }
        },
        clearLocationRingsForOtherStopes(stopeId: string) {
            this.rings = this.rings.filter(x => x.stopeId === stopeId);
        },
        clearLocationRingsForAllStopes() {
            this.rings = [];
        },
        async populateTriggerActionResponsePlans(departmentId: string) {
            const tarps = await TriggerActionResponsePlans.list(departmentId);

            if (tarps) {
                this.tarpAspects = tarps.aspects;
            }
        },
        getLocationRingStopeId(locationRingId: string): string | null {
            return this.rings.find(x=> x.rings.some(r => r.id === locationRingId))?.id ?? null;
        },
        getLocationRingName(stopeId: string, locationRingId: string) {
            const stopeLocations = this.rings.filter((x) => x.stopeId === stopeId);
            if (stopeLocations.length > 0) {
                const ring = _.flatMap(stopeLocations, s => s.rings).find((x) => x.id === locationRingId);
                if (ring) {
                    return ring.name;
                }
            }

            return '?';
        },
        getLocationRingNameByIndex(locationId: string, index: number) {
            if (!index) return '';

            const stope = this.rings.find((x) => x.id === locationId);
            if (stope) {
                const ring = stope.rings.find((x) => x.displayOrder === index);
                if (ring) {
                    return ring.name;
                }
            }

            return `${index}`;
        },
        async getBlastPacketProgressByTaskId(plannedTaskId: string) {
            return await BlastPackets.getProgress(plannedTaskId);
        },
        getExtendedProperty(location: CommonLocationTrackingViewModel, propertyDefinition: PropertyDefinitionModel): ExtendedProperty {
            const index = location.extendedProperties.findIndex(e => e.propertyDefinitionId === propertyDefinition.id);
            const prop = index == -1 ? {
                propertyDefinitionId: propertyDefinition.id!,
                value: ''
            } as ExtendedProperty : {
                propertyDefinitionId: location.extendedProperties[index].propertyDefinitionId!,
                id: location.extendedProperties[index].id,
                value: location.extendedProperties[index].value!
            };
            return prop;
        },
        async executeCreateNewLocationRing(stopeId: string | null, command: CreateLocationRingCommand) {
            await LocationRings.executeCreateCommand(command);

            if(stopeId != null){
                const reloadedRings = await LocationRings.getByStope(this._departmentId, stopeId);
                if (reloadedRings != null)
                    this.rings = [...this.rings.filter(x => x.stopeId !== stopeId), ...reloadedRings.locations];
            }
        },
        async executeUpdateLocationRing(command: UpdateLocationRingCommand) {
            await LocationRings.executeUpdateCommand(command);

            const existingRing = this.rings.find(x => x.rings.some(r => r.id === command.id));

            if (existingRing?.stopeId != null) {
                const reloadedRings = await LocationRings.getByStope(this._departmentId, existingRing.stopeId);
                if (reloadedRings != null)
                    this.rings = [...this.rings.filter(x => x.stopeId !== existingRing.stopeId), ...reloadedRings.locations];
            }
        },
        async executeDeleteLocationRing(stopeId: string, locationRingId: string) {
            const deleteCommand: DeleteLocationRingCommand = {
                _type: 'DeleteLocationRingCommand',
                id: locationRingId
            };

            await LocationRings.executeDeleteCommand(deleteCommand);
            const lr = this.rings.find((x) => x.id == stopeId);
            if (lr) {
                const locationRingIndex = lr.rings.findIndex((x) => x.id == locationRingId);
                if (locationRingIndex !== -1) {
                    lr.rings.splice(locationRingIndex, 1);
                }
            }

            await useBlastPacketsStore().refreshBlastPacketsForStope(stopeId);
        },
        async executeUpdateLocationStatus(command: UpdateLocationStatusCommand) {
            await Locations.executeUpdateStatusCommand(command);

            if (this.patchLocationCommonInformationCallback != null) {
                this.patchLocationCommonInformationCallback(command.locationId!, (commonInfo: ClientCommonLocationTracking) => {
                    commonInfo.locationStatus = command.status;
                    return commonInfo;
                });
            }
        },
        async executeUpdateLocationCommentary(locationId: string, newCommentary: CommentaryViewModel) {
            const command = ModelBuilder.Commands.UpdateLocationCommentaryCommand(locationId, newCommentary.comment ?? '');
            await Locations.executeUpdateCommentary(command);

            if (this.patchLocationCommonInformationCallback != null) {
                this.patchLocationCommonInformationCallback(command.locationId!, (commonInfo: ClientCommonLocationTracking) => {
                    commonInfo.commentary = newCommentary;
                    return commonInfo;
                });
            }
        },
        async executeUpdateLocationTARPStatus(command: UpdateLocationTriggerActionResponsePlanStatusCommand) {
            await Locations.executeUpdateTARPStatusCommand(command);
            const tarpAspect = this.tarpAspects.find(x => x.id === command.planId);

            if (tarpAspect == null)
                return;

            const status = tarpAspect.statuses.find(x => x.id === command.statusId);

            if (status == null)
                return;

            if (this.patchLocationCommonInformationCallback != null) {
                this.patchLocationCommonInformationCallback(command.locationId!, (commonInfo: ClientCommonLocationTracking) => {
                    const tarpStatus = commonInfo.triggerActionResponsePlanStatuses.find(t => t.aspectId === command.planId);
                    if (tarpStatus != null) {
                        tarpStatus.statusId = command.statusId;
                        tarpStatus.statusColour = status.colour;
                        tarpStatus.statusDescription = status.description;
                        tarpStatus.statusName = status.name;
                        tarpStatus.modifiedAt = new Date();
                    } else {
                        commonInfo.triggerActionResponsePlanStatuses.push({
                            _type: 'LocationTrackingTriggerActionResponseStatusViewModel',
                            aspectId: command.planId,
                            statusId: command.statusId,
                            statusColour: status.colour,
                            statusDescription: status.description,
                            statusName: status.name,
                            modifiedAt: new Date()
                        });
                    }
                    return commonInfo;
                });
            }
        },
        async executeUpdateLocationDetails(command: UpdateLocationDetailsCommand) {
            await LocationDetails.send(command);

            if (this.patchLocationCommonInformationCallback != null) {
                this.patchLocationCommonInformationCallback(command.locationId!, (commonInfo: ClientCommonLocationTracking) => {
                    commonInfo.details = {
                        _type: 'LocationTrackingLocationDetailsViewModel',
                        stockpileId: command.stockpileId,
                        materialDestinationId: command.materialDestinationId,
                        materialTypeId: command.materialTypeId
                    };
                    return commonInfo;
                });
            }
        },
        async executeUpdateExtendedProperty(command: UpsertLocationExtendedPropertyCommand): Promise<{ id: string }> {
            const response = await Locations.ExecuteUpsertExtendedProperty(command);

            if (this.patchLocationCommonInformationCallback != null) {
                this.patchLocationCommonInformationCallback(command.locationId!, (commonInfo: ClientCommonLocationTracking) => {
                    const index = commonInfo.extendedProperties.findIndex(e => e.id === response.id);
                    if (index != -1) {
                        commonInfo.extendedProperties[index].value = command.value;
                    } else {
                        commonInfo.extendedProperties.push({
                            _type: 'ExtendedPropertyInfo',
                            id: response.id,
                            propertyDefinitionId: command.propertyDefinitionId,
                            value: command.value
                        });
                    }
                    return commonInfo;
                });
            }

            return response;
        }
    }
});
